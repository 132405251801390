import * as ExcelJS from 'exceljs';

export type SheetsHeaders = Record<string, string>;

export type SheetsLeafValue = string | number | boolean | Date | null | undefined;
export interface SheetsNodeRecord {
    [key: string]: SheetsLeafValue | SheetsNodeRecord;
}
export type SheetsRecord = Record<string, SheetsLeafValue | SheetsNodeRecord>;


export interface SheetsOptions {
    booleanParser: (value: boolean) => string;
    numberParser: (value: number) => string | number;
    dateParser: (value: Date) => string;
    nullParser: (value: null) => string;
    undefinedParser: (value: undefined) => string;
}

const DEFAULT_OPTIONS: SheetsOptions = {
    booleanParser: value => value ? 'SÌ' : 'NO',
    numberParser: value => value,
    dateParser: value => value.toLocaleDateString(),
    nullParser: () => '',
    undefinedParser: () => ''
};

function mergeOptions(options: Partial<SheetsOptions>): SheetsOptions {
    return {
        ...DEFAULT_OPTIONS,
        ...options
    };
}

function parseValue(value: SheetsLeafValue, options: SheetsOptions): string | number {
    if (typeof value === 'number') {
        return options.numberParser(value);
    }
    else if (typeof value === 'boolean') {
        return options.booleanParser(value);
    }
    else if (value === null) {
        return options.nullParser(value);
    }
    else if (value === undefined) {
        return options.undefinedParser(value);
    }
    else if (value instanceof Date) {
        return options.dateParser(value);
    }
    return value;
}

function followKey(key: string, record: SheetsRecord, options: SheetsOptions): string | number {
    const keys = key.split('.');
    const value = record[keys[0]] as unknown as SheetsLeafValue;

    return keys.length === 1 
        ? parseValue(value, options) 
        : followKey(keys.slice(1).join('.'), value as unknown as SheetsRecord, options);
}

function obtainHeaderAndRows(headers: SheetsHeaders, records: SheetsRecord[], options: SheetsOptions): { headerRow: string[], rows: (string | number)[][] } {
    const keys = Object.keys(headers);
    const headerRow = keys.map(key => headers[key]);
    const rows = records.map(record => keys.map(key => followKey(key, record, options)));
    return { headerRow, rows };
}

export function createTsvBlob(headers: SheetsHeaders, records: SheetsRecord[], options: Partial<SheetsOptions> = {}): Blob {
    const opts = mergeOptions(options);
    const { headerRow, rows } = obtainHeaderAndRows(headers, records, opts);

    const text = [headerRow, ...rows].map(row => row.join('\t')).join('\n');

    const blob = new Blob([text], {
        type: 'text/tab-separated-values'
    });
    return blob;
}

export async function createXlsxBlob(title: string, headers: SheetsHeaders, records: SheetsRecord[], options: Partial<SheetsOptions> = {}): Promise<Blob> {
    const opts = mergeOptions(options);
    const { headerRow, rows } = obtainHeaderAndRows(headers, records, opts);

    const HEADER_FSIZE = 12;
    const HEADER_BORDER_WIDTH = 'medium';

    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Eugenio Vinicio Berretta <euberdeveloper@gmail.com>';
    workbook.created = new Date();
    workbook.properties.date1904 = true;

    const sheet = workbook.addWorksheet(title, {
        headerFooter: {
            firstHeader: title
        }
    });

    const dataRowOffset = 2;
    const hRow = sheet.getRow(dataRowOffset);
    hRow.height = HEADER_FSIZE + HEADER_FSIZE / 2;
    hRow.alignment = { vertical: 'middle' };

    for (let i = 0; i < headerRow.length; i++) {
        const value = headerRow[i];

        const column = sheet.getColumn(i + 2);
        column.width = value.length * 2;
        column.alignment = { horizontal: 'center' };

        const cell = hRow.getCell(i + 2);
        cell.value = value;
        cell.border = {
            top: { style: HEADER_BORDER_WIDTH },
            left: { style: HEADER_BORDER_WIDTH },
            right: { style: HEADER_BORDER_WIDTH },
            bottom: { style: HEADER_BORDER_WIDTH }
        };
        cell.font = { size: HEADER_FSIZE, bold: true };
    }

    for (let i = 0; i < rows.length; i++) {
        const row = sheet.getRow(i + dataRowOffset + 1);
        const isLastRow = i === rows.length - 1;

        for (let j = 0; j < rows[i].length; j++) {
            const value = rows[i][j];
            const cell = row.getCell(j + 2);

            cell.value = value;

            const isFirstColumn = j === 0;
            const isLastColumn = j === rows[i].length - 1;

            cell.border = {
                left: {
                    style: isFirstColumn ? HEADER_BORDER_WIDTH : 'thin',
                    color: isFirstColumn ? { argb: '00000000' } : undefined
                },
                right: {
                    style: isLastColumn ? HEADER_BORDER_WIDTH : 'thin',
                    color: isLastColumn ? { argb: '00000000' } : undefined
                },
                bottom: {
                    style: isLastRow ? HEADER_BORDER_WIDTH : 'thin',
                    color: isLastRow ? { argb: '00000000' } : undefined
                }
            };
        }
    }

    const buffer = await workbook.xlsx.writeBuffer();

    const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    return blob;
}