








import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import VSnackbars from "v-snackbars";

@Component({
  components: {
    VSnackbars,
  },
  model: {
    prop: "messages",
    event: "messages-updated",
  },
})
export default class OperatnErrorsQueue extends Vue {
  /* PROPS */

  @Prop({ type: Array, default: () => [] })
  messages!: string[];

  /* GETTERS AND SETTERS */

  get intenalMessages(): string[] {
    return this.messages;
  }
  set intenalMessages(value: string[]) {
    this.$emit("messages-updated", value);
  }
}
