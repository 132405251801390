import Vue from 'vue';
import { OperaTN } from "operatn-api-client";

import CONFIG from '@/config';

const api = new OperaTN(CONFIG.API_URL, null, null, {
    withCredentials: CONFIG.AXIOS_WITH_CREDENTIALS,
    timeout: CONFIG.AXIOS_TIMEOUT,
    timeoutErrorMessage: 'Il server ci sta mettendo troppo tempo, richiesta annullata. Nota che il server potrebbe comunque continuare a lavorare.'
});

const apiPlugin = {
    install: function (Vue) {
        Vue.prototype.$api = api;
    }
}

Vue.use(apiPlugin);

export default api;