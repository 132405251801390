import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import OperatnItalianIcon from '@/components/gears/icons/OperatnItalianIcon.vue';
import OperatnEuropeanIcon from '@/components/gears/icons/OperatnEuropeanIcon.vue';
import OperatnGlobeIcon from '@/components/gears/icons/OperatnGlobeIcon.vue';
import OperatnEnglishIcon from '@/components/gears/icons/OperatnEnglishIcon.vue';
import OperatnUkrainianIcon from '@/components/gears/icons/OperatnUkrainianIcon.vue';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        values: {
            italian: {
                component: OperatnItalianIcon
            },
            european: {
                component: OperatnEuropeanIcon
            },
            globe: {
                component: OperatnGlobeIcon
            },
            english: {
                component: OperatnEnglishIcon
            },
            ukrainian: {
                component: OperatnUkrainianIcon
            }
        }
    }
});
