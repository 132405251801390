
























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  model: {
    prop: 'show',
    event: 'show-updated'
  }
})
export default class OperatnErrorDialog extends Vue {
  /* PROPS */

  @Prop({ type: String, default: 'error' })
  color!: string;

  @Prop({ type: String, default: 'lighten-1'})
  colorModificator!: string;

  @Prop({ type: String, default: 'Errore'})
  title!: string;

  @Prop({ type: Boolean })
  show!: boolean;

  @Prop({ type: String })
  text!: string;

  /* GETTERS AND SETTERS */

  get internalShow(): boolean {
    return this.show;
  }
  set internalShow(value: boolean) {
    this.$emit('show-updated', value);
  }
}
