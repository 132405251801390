import utenti from './markdowns/utenti.md';
import tipiStanza from './markdowns/tipi_stanza.md';
import tipiFabbricato from './markdowns/tipi_fabbricato.md';
import tipiStudente from './markdowns/tipi_studente.md';
import tipiTariffa from './markdowns/tipi_tariffa.md';
import tipiOspite from './markdowns/tipi_ospite.md';
import tipiContratto from './markdowns/tipi_contratto.md';
import tipiBolletta from './markdowns/tipi_bolletta.md';
import contrattiDaFirmare from './markdowns/contratti_da_firmare.md';

export default {
    utenti,
    tipiStanza,
    tipiFabbricato,
    tipiStudente,
    tipiTariffa,
    tipiOspite,
    tipiContratto,
    tipiBolletta,
    contrattiDaFirmare
};