import Vue from 'vue';

import axios from "axios";

import CONFIG from '@/config';
import { downloadBlob } from '@/utils';

export interface Stored {
    getPath: (path: string) => string;
    getBlob: (path: string) => Promise<Blob>;
    download: (path: string, name: string) => Promise<void>;
}

const stored: Stored = {
    getPath(path: string): string {
        path = path[0] === '/' ? path.substr(1) : path;
        return `${CONFIG.STORED_URL}/${path}`;
    },
    async getBlob(path: string): Promise<Blob> {
        const response = await axios.get(stored.getPath(path), { responseType: "blob" });
        return response.data;
    },
    async download(path: string, name: string): Promise<void> {
        downloadBlob(await stored.getBlob(path), name);
    }
}


const storedPlugin = {
    install: function (Vue) {
        Vue.prototype.$stored = stored;
    }
}

Vue.use(storedPlugin);

export default stored;