


























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import VueMarkdown from "@adapttive/vue-markdown";

@Component({
  model: {
    prop: "show",
    event: "show-updated",
  },
  components: {
    VueMarkdown,
  },
})
export default class OperatnInfoDialog extends Vue {
  /* PROPS */

  @Prop({ type: String, default: "primary" })
  color!: string;

  @Prop({ type: String, default: "lighten-1" })
  colorModificator!: string;

  @Prop({ type: String, default: "Informazioni" })
  title!: string;

  @Prop({ type: Boolean })
  show!: boolean;

  @Prop({ type: String })
  text!: string;

  @Prop({ type: String, default: "45vw" })
  width!: string;


  /* GETTERS AND SETTERS */

  get internalShow(): boolean {
    return this.show;
  }
  set internalShow(value: boolean) {
    this.$emit("show-updated", value);
  }
}
