

























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  model: {
    prop: "show",
    event: "show-updated",
  },
})
export default class OperatnConfirmDialog extends Vue {
  /* PROPS */

  @Prop({ type: String, default: "warning" })
  color!: string;

  @Prop({ type: String, default: "lighten-1" })
  colorModificator!: string;

  @Prop({ type: String, default: "Attenzione" })
  title!: string;

  @Prop({ type: Function, required: true })
  callback!: (answer: boolean) => void | Promise<void>;

  @Prop({ type: Boolean })
  show!: boolean;

  @Prop({ type: String })
  text!: string;

  @Prop({ type: Boolean, default: true })
  persistent!: boolean;

  /* GETTERS AND SETTERS */

  get internalShow(): boolean {
    return this.show;
  }
  set internalShow(value: boolean) {
    this.$emit("show-updated", value);
  }

  /* METHODS */

  async action(answer: boolean): Promise<void> {
    this.internalShow = false;
    await this.callback(answer);
  }
}
